import type { DataWorkerMeta } from '@openphone/data-worker/meta'
import { createClient } from '@openphone/workerql-core/client'
import { captureException } from '@sentry/react'
import { Environment, Network, Observable, RecordSource, Store } from 'relay-runtime'

import loadDataLayerWorker from '@src/service/worker/loadDataLayerWorker'

import createRelaySentryLogFunction from './createRelaySentryLogFunction'
import relayFieldLogger from './relayFieldLogger'

export type WorkerQLRelayEnvironment = ReturnType<typeof createWorkerQLRelayEnvironment>

interface CreateWorkerQLRelayEnvironment {
  meta: DataWorkerMeta
}

export async function createWorkerQLRelayEnvironment({
  meta,
}: CreateWorkerQLRelayEnvironment) {
  const graphqlWorkerClient = createClient({
    createSocket: async () => {
      const { socket } = loadDataLayerWorker(meta)
      return socket
    },
    debug: import.meta.env.DEV,
    onFatalError: (error) => {
      captureException(error, {
        tags: {
          workerql_log_kind: 'fatal_error',
        },
      })
    },
  })

  await graphqlWorkerClient.connect()

  const network = Network.create((operation, variables) => {
    return Observable.create((sink) => {
      if (!operation.text) {
        return
      }

      return graphqlWorkerClient.subscribe(
        {
          query: operation.text,
          variables,
        },
        {
          // @ts-expect-error -- Types are wrong but it's fine
          next: sink.next.bind(sink),
          complete: sink.complete.bind(sink),
          error: sink.error.bind(sink),
        },
      )
    })
  })

  const environment = new Environment({
    network,
    store: new Store(new RecordSource()),
    relayFieldLogger,
    log: createRelaySentryLogFunction(),
  })

  if (import.meta.env.DEV) {
    import('@openphone/workerql-core/devtools').then((devtools) => {
      devtools.loadGraphQLWorkerDevTools(graphqlWorkerClient)
    })
  }

  window.addEventListener('beforeunload', () => {
    // This will notify the server to close the connection and release
    // all resources associated with this client
    graphqlWorkerClient.close('unload')
  })

  return environment
}
