import type { AnalyticsBrowser } from '@dreamdata/analytics-next'

import type { GreetingOption } from '@src/app/command/SetGreetingCommand'
import type { ContactFilterType } from '@src/app/contacts/filters'
import { isTollFreePhoneNumber } from '@src/lib/phone-number'
import type Heap from '@src/service/analytics/Heap'
import type { AvailablePhoneNumber } from '@src/service/transport/account'
import type { ContactItemType } from '@src/service/transport/contacts'

export type BusinessPreviewTrigger =
  // From the billing page:
  | 'billing page callout'
  // From a url param:
  | 'url'
  // Business plan features that first open the upgrade modal:
  | 'analytics'
  | 'hubspot integration'
  | 'salesfoce integration'
  | 'gong integration'
  | 'ring order'
  | 'auto recording'
  | 'call transcriptions'
  | 'group calling'
  | 'call hold'
  | 'call transfer'
  | 'phone menu'
  // Legacy Business plan features (only available to legacy users):
  | 'phone menu destination'
  | 'phone menu default action'
  // Fallback value, shouldn't happen in practice:
  | 'unknown'

export default class WorkspaceAnalyticsStore {
  constructor(
    private readonly heap: Heap,
    private readonly dreamdata: AnalyticsBrowser,
  ) {}

  trackPageView() {
    this.dreamdata.page()
  }

  /**
   * Tracks when a user has logged in
   *
   * @param method - Method used to log in
   */
  login(method: 'password' | 'passwordless' | 'google') {
    window.dataLayer.push({
      event: 'login',
      method,
    })

    this.dreamdata.track('form submission - login', { method })

    this.heap.track('login', { method })
  }

  /**
   * Tracks when a user has signed up
   *
   * @param method - Method used to sign up
   */
  signup(method: 'passwordless' | 'google') {
    window.dataLayer.push({
      event: 'signup',
      method,
    })

    this.dreamdata.track('form submission - signup', { method })

    this.heap.track('signup', { method })
  }

  /**
   * Tracks when a user has created a Workspace
   */
  createdWorkspace() {
    window.dataLayer.push({
      event: 'create_workspace',
    })

    this.heap.track('create_workspace')
  }

  /**
   * Tracks when a user has started a trial
   */
  startedTrial() {
    window.dataLayer.push({
      event: 'start_trial',
    })

    this.heap.track('start_trial')
  }

  /**
   * Tracks when a user has switched manually from trial to paid
   */
  switchedToPaid() {
    this.heap.track('switched_to_paid')
  }

  /**
   * Tracks when a user has opened the Business trial modal
   *
   * @param trigger - Where the modal was opened from
   */
  businessTrialModalOpened(trigger: BusinessPreviewTrigger) {
    this.heap.track('business_trial_modal_opened', { trigger })
  }

  /**
   * Tracks when a user clicks the button to start the Business trial
   *
   * @param trigger - Where the modal was opened from when the trial was started
   */
  businessTrialStarted(trigger: BusinessPreviewTrigger) {
    this.heap.track('business_trial_started', { trigger })
  }

  /**
   * Tracks when a user has added payment info
   */
  addedPaymentInfo() {
    window.dataLayer.push({
      event: 'payment_info_added',
    })

    this.heap.track('payment_info_added')
  }

  /**
   * Tracks when a user has updated payment info
   */
  updatedPaymentInfo() {
    window.dataLayer.push({
      event: 'payment_info_updated',
    })

    this.heap.track('payment_info_updated')
  }

  /**
   * Tracks when the user has viewed the plans comparison inside the billing settings
   */
  billingPlansViewed() {
    this.heap.track('billing_plans_viewed')
  }

  /**
   * Tracks when a user has invited someone
   */
  invited() {
    window.dataLayer.push({
      event: 'invite',
    })

    this.heap.track('invite')
  }

  /**
   * Tracks when a user has accepted an invite to join a workspace
   *
   * @param companyName - Name of the company/organization that owns the workspace
   */
  userAcceptedInvite(companyName?: string) {
    this.heap.track('user_accepted_invite', { companyName })
  }

  /**
   * Tracks when a phone number is added to the workspace
   *
   * @param orgId
   * @param phoneNumber
   * @param type - Whether the number was added to be used by a member or as a workspace inbox
   */
  numberCreated(orgId: string, phoneNumber: string, type: 'member' | 'workspace') {
    const numberType = isTollFreePhoneNumber(phoneNumber) ? 'toll-free' : 'local'
    this.heap.track('number_created', { orgId, phoneNumber, type, numberType })
  }

  /**
   * Tracks when a user is added to an inbox, including the first user that is added when the inbox is created for the first time.
   *
   * @param phoneNumberId
   * @param inboxUserCount - Number of users sharing the inbox
   * @param inboxRole - Role assigned to the user within the inbox
   */
  userAddedToInbox(phoneNumberId: string, inboxUserCount: number, inboxRole: string) {
    this.heap.track('user_added_to_inbox', { phoneNumberId, inboxUserCount, inboxRole })
  }

  /**
   * Tracks when a phone number is removed from the workspace
   *
   * @param orgId
   * @param phoneNumber
   */
  numberDeleted(orgId: string, phoneNumber: string): void {
    this.heap.track('number_deleted', { orgId, phoneNumber })
  }

  /**
   * Tracks when a porting request has been initiated
   *
   * @param orgId
   * @param phoneNumber
   * @param isResubmitting - Whether the port is being resubmitted
   */
  portInitiated(orgId: string, phoneNumber: string, isResubmitting: boolean): void {
    this.heap.track('port_initiated', { orgId, phoneNumber, isResubmitting })
  }

  /**
   * Tracks when a porting request has failed
   *
   * @param orgId
   * @param phoneNumber
   * @param errorCode
   * @param errorMessage
   */
  portFailed(
    orgId: string,
    phoneNumber: string,
    errorCode: string,
    errorMessage: string,
  ): void {
    this.heap.track('port_failed', { orgId, phoneNumber, errorCode, errorMessage })
  }

  /**
   * Tracks when a user has cancelled the trial or subscription
   */
  cancellation() {
    window.dataLayer.push({
      event: 'cancellation',
    })

    this.heap.track('cancellation')
  }

  referralClicked(
    action:
      | 'settings_tab_clicked'
      | 'account_menu_tab_clicked'
      | 'side_menu_cta_clicked'
      | 'profile_cta_clicked'
      | 'open_new_window_button_clicked'
      | 'copy_link_button_clicked'
      | 'link_copied'
      | 'partner_apply_now_button_clicked'
      | 'partner_learn_more_button_clicked',
  ): void

  referralClicked(
    action: 'social_button_clicked',
    network: 'facebook' | 'twitter' | 'linkedin' | 'email',
  ): void

  /**
   * Tracks when a user clicks something related to referrals
   *
   * @param action
   * @param network
   */
  referralClicked(action: string, network?: string): void {
    this.heap.track('referral_clicked', { action, network })
  }

  /**
   * Tracks when a user clicks on the help and support menu
   *
   * @param action
   */
  helpAndSupportClicked(action: string) {
    this.heap.track('help_and_support_clicked', { action })
  }

  /**
   * Tracks when a user toggles the dyslexia mode
   *
   * @param enabled
   */
  dyslexiaModeToggled(enabled: boolean) {
    this.heap.track('dyslexia_mode_toggled', { enabled })
  }

  /**
   * Tracks when the user filters the contacts
   *
   * @param filter
   * @param totalFilters
   */
  contactsFiltered(filter: ContactFilterType, totalFilters: number) {
    this.heap.track('contacts_filtered', { filter, total_filters: totalFilters })
  }

  /**
   * Tracks when a contact filter has been cleared
   *
   * @param filter
   * @param totalFilters
   */
  contactsFilterCleared(filter: ContactFilterType, totalFilters: number) {
    this.heap.track('contacts_filter_cleared', { filter, totalFilters })
  }

  /**
   * Tracks when the contacts filter is collapsed
   *
   * @param totalFilters
   */
  contactsFiltersCollapsed(totalFilters: number) {
    this.heap.track('contacts_filters_collapsed', { totalFilters })
  }

  /**
   * Tracks when the contacts filter is expanded
   *
   * @param totalFilters
   */
  contactsFiltersExpanded(totalFilters: number) {
    this.heap.track('contacts_filters_expanded', { totalFilters })
  }

  /**
   * Tracks when a contact is created
   */
  contactCreated() {
    this.heap.track('contact_created')
  }

  /**
   * Tracks when a contact is updated
   */
  contactUpdated() {
    this.heap.track('contact_updated')
  }

  /**
   * Tracks when a contact note is created
   */
  contactNoteCreated() {
    this.heap.track('contact_note_created')
  }

  /**
   * Tracks when the default contact sharing settings have been changed
   */
  defaultContactSharingChanged() {
    this.heap.track('default_contact_sharing_changed')
  }

  /**
   * Tracks when Google contact integration is connected successfully
   */
  googleContactsIntegrationConnected() {
    this.heap.track('google_contacts_integration_connected')
  }

  /**
   * Tracks when user successfully imports contact CSV
   */
  contactsCsvImported() {
    this.heap.track('contacts_csv_imported')
  }

  /**
   * Tracks when a user creates a new custom property on a contact
   *
   * @param customFieldType
   */
  customContactPropertyCreated(customFieldType: ContactItemType) {
    this.heap.track('custom_contact_property_created', { customFieldType })
  }

  /**
   * Tracks when an auto reply is saved
   *
   * @param phoneNumberId
   * @param autoReplyType
   */
  autoReplySaved(phoneNumberId: string, autoReplyType: string) {
    this.heap.track('auto_reply_saved', { phoneNumberId, autoReplyType })
  }

  /**
   * Tracks when a user enables the phone menu on a number
   *
   * @param phoneNumberId
   * @param enabled
   * @param menuOptions - Number of menu options created
   */
  phoneMenuToggled(phoneNumberId: string, enabled: boolean, menuOptions: number) {
    this.heap.track('phone_menu_toggled', { phoneNumberId, enabled, menuOptions })
  }

  /**
   * Tracks when a user selects an option for the after hours call forwarding
   *
   * @param type - The option selected by the user
   * @param phoneNumberSelectionType - The type of phone number selected by the user in the case of a call forwarding
   */
  afterHoursCallForwardChanged(
    type: 'external' | 'internal' | 'playAudio' | 'voicemail',
    phoneNumberSelectionType:
      | 'add-number'
      | 'contact'
      | 'member'
      | 'inbox'
      | 'inbox-member'
      | 'number'
      | null,
  ) {
    this.heap.track('after_hours_call_fwd_changed', { type, phoneNumberSelectionType })
  }

  /**
   * Tracks when a user selects an option for the unanswered calls forwarding
   *
   * @param type - The option selected by the user
   * @param phoneNumberSelectionType - The type of phone number selected by the user in the case of a call forwarding
   */
  unansweredCallForwardChanged(
    type: 'external' | 'internal' | 'playAudio' | 'voicemail',
    phoneNumberSelectionType:
      | 'add-number'
      | 'contact'
      | 'member'
      | 'inbox'
      | 'inbox-member'
      | 'number'
      | null,
  ) {
    this.heap.track('unanswered_call_fwd_changed', { type, phoneNumberSelectionType })
  }

  /**
   * Tracks when a user makes a change and saves the ring order settings
   *
   * @param phoneNumberId
   * @param ringOrderType
   */
  ringOrderSaved(
    phoneNumberId: string,
    ringOrderType: 'random' | 'custom' | 'all-at-once',
  ) {
    this.heap.track('ring_order_saved', { phoneNumberId, ringOrderType })
  }

  /**
   * Tracks when a user saves a custom voicemail
   *
   * @param phoneNumberId
   * @param voicemailType
   */
  customVoicemailSaved(phoneNumberId: string, voicemailType: GreetingOption) {
    this.heap.track('custom_voicemail_saved', { phoneNumberId, voicemailType })
  }

  /**
   * Tracks when a user toggles the business hours on a phone number
   *
   * @param phoneNumberId
   * @param enabled
   */
  businessHoursToggled(phoneNumberId: string, enabled: boolean) {
    this.heap.track('business_hours_toggled', { phoneNumberId, enabled })
  }

  /**
   * Tracks when a new user group is created
   *
   * @param numberOfUsers
   * @param name
   */
  userGroupCreated(numberOfUsers: number, name: string) {
    this.heap.track('user_group_created', { numberOfUsers, name })
  }

  /**
   * Tracks when a user is added to a member group after it was already created
   */
  userAddedToGroup() {
    this.heap.track('user_added_to_group')
  }

  /**
   * Tracks when a user toggles auto call recording from the phone number settings
   *
   * @param phoneNumberId
   * @param enabled
   */
  autoCallRecordingToggled(phoneNumberId: string, enabled: boolean) {
    this.heap.track('auto_call_recording_toggled', { phoneNumberId, enabled })
  }

  /**
   * Tracks when a user toggles call transcription and summary in the phone number settings
   *
   * @param phoneNumberId
   * @param enabled
   */
  callTranscriptionToggled(phoneNumberId: string, enabled: boolean) {
    this.heap.track('call_transcription_toggled', { phoneNumberId, enabled })
  }

  /**
   * Tracks when a user toggles international calling and messaging in the phone number settings
   *
   * @param phoneNumberId
   * @param enabled
   */
  internationalCallingMessagingToggled(phoneNumberId: string, enabled: boolean) {
    this.heap.track('international_calling_messaging_toggled', { phoneNumberId, enabled })
  }

  /**
   * Tracks when call forwarding is toggled in the phone number settings
   *
   * @param phoneNumberId
   * @param enabled
   */
  callForwardingToggled(phoneNumberId: string, enabled: boolean) {
    this.heap.track('call_forwarding_toggled', { phoneNumberId, enabled })
  }

  /**
   * Tracks when a user clicks on the "Copy invite link" button
   */
  inviteLinkCopied() {
    this.heap.track('invite_link_copied')
  }

  /**
   * Tracks when a user clicks on the "Pick a different number" button in the phone number step of the onboarding
   */
  phoneNumberPickerOpened() {
    this.heap.track('phone_number_picker_opened')
  }

  /**
   * Tracks when a user picks a different phone number than the one they got assigned in the phone number step of the onboarding
   */
  phoneNumberPicked(phoneNumber: AvailablePhoneNumber) {
    this.heap.track('phone_number_picked', { ...phoneNumber })
  }

  /**
   * Tracks when a user dismisses the mobile app prompt
   */
  mobileAppPromptDismissed() {
    this.heap.track('app_prompt_dismissed')
  }

  /**
   * Tracks when a user clicks the button to get the mobile app
   */
  mobileAppPromptTapped() {
    this.heap.track('app_prompt_tapped')
  }

  /**
   * Tracks when the ai contact suggestion side panel gets displayed
   */
  aiContactSuggestionDisplayed(suggestedFieldsKeys: string[]) {
    this.heap.track('ai_contact_suggestion_displayed', { suggestedFieldsKeys })
  }

  /**
   * Tracks when the ai contact suggestion is accepted
   */
  aiContactSuggestionAccepted(suggestedFieldsKeys: string[]) {
    this.heap.track('ai_contact_suggestion_accepted', { suggestedFieldsKeys })
  }

  /**
   * Tracks when the ai contact suggestion is dismissed
   */
  aiContactSuggestionDismissed(suggestedFieldsKeys: string[]) {
    this.heap.track('ai_contact_suggestion_dismissed', { suggestedFieldsKeys })
  }

  /**
   * Tracks when the ai contact suggestion is edited
   */
  aiContactSuggestionEdited(editedFieldsKeys: string[], suggestedFieldsKeys: string[]) {
    this.heap.track('ai_contact_suggestion_edited', {
      suggestedFieldsKeys,
      editedFieldsKeys,
    })
  }

  /**
   * Tracks when the command to add members to an inbox is opened
   * @param from - Where the command was opened from
   */
  shareInboxCommandOpened(from: 'inboxMenu' | 'settings') {
    this.heap.track('share_inbox_command_opened', { from })
  }

  /**
   * Tracks when the inbox dropdown inside the invite modal is expanded
   */
  inviteModalInboxDropdownExpanded() {
    this.heap.track('invite_modal_inbox_dropdown_expanded')
  }

  /**
   * Tracks when the role dropdown inside the invite modal is expanded
   */
  inviteModalRoleDropdownExpanded() {
    this.heap.track('invite_modal_role_dropdown_expanded')
  }

  /**
   * Tracks when invites are sent from the invite modal
   * @param newNumberRemoved
   * @param sharedInboxSelected
   */

  invitesSent({
    newNumberRemoved,
    sharedInboxSelected,
  }: {
    newNumberRemoved: boolean
    sharedInboxSelected: boolean
  }) {
    this.heap.track('invites_sent', { newNumberRemoved, sharedInboxSelected })
  }
}
