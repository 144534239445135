/* eslint-disable canonical/filename-match-exported -- FIXME: Fix this ESLint violation! */
import type { Env } from '@openphone/web-config'
import { ENV_OVERRIDE_KEY } from '@openphone/web-config'
import { makeAutoObservable } from 'mobx'

import { AnalyticsStore } from '@src/service/analytics'
import { CapabilitiesService } from '@src/service/capabilities'

import AiMessageResponseStore from './AiMessageResponseStore'
import AiStore from './AiStore'
import BlocklistStore from './BlocklistStore'
import CallFallbackConfigStore from './CallFallbackConfigStore'
import CnamStore from './CnamStore'
import CommentStore from './CommentStore'
import ContactSuggestionStore from './ContactSuggestionStore'
import MediaStore from './MediaStore'
import PortRequestStore from './PortRequestStore'
import RingOrderStore from './RingOrderStore'
import ScheduledMessageStore from './ScheduledMessageStore'
import TagStore from './TagStore/TagStore'
import TollFreeRegistrationStore from './TollFreeRegistrationStore'
import TrustRegistrationV2Store from './TrustRegistrationV2Store'
import UniversalLoginAuthStore from './UniversalLoginAuthStore'
import WorkflowStore from './WorkflowStore'
import WorkspaceStore from './WorkspaceStore'
import ActivityStore from './activity-store'
import AlertStore from './alert-store'
import AuthStore from './auth-store'
import BillingStore from './billing/BillingStore'
import ContactStore from './contact-store'
import ConversationStore from './conversation-store'
import EmojiService from './emoji-service'
import type { IFlagsService } from './feature-flags'
import { FlagsService } from './feature-flags'
import IntegrationStore from './integration-store'
import MemberStore from './member-store'
import OrganizationStore from './organization-store'
import ParticipantStore from './participant-store'
import PhoneNumberStore from './phone-number-store'
import PresenceService from './presence/PresenceService'
import ReferralStore from './referral-store'
import SearchStore from './search-store'
import SnippetsStore from './snippet-store'
import StorageService from './storage/StorageService'
import SurveyService from './survey/SurveyService'
import Transport from './transport'
import UserStore from './user-store'
import { VoiceStore } from './voice'
import MainWorker from './worker/main'

export interface ServiceConfig {
  workerName: string
  __mock?: {
    worker: MainWorker
    storage: StorageService
    flags?: IFlagsService
  }
}

export default class Service {
  readonly storage: StorageService
  readonly presence: PresenceService
  readonly transport: Transport
  readonly activity: ActivityStore
  readonly ai: AiStore
  readonly alert: AlertStore
  readonly auth: AuthStore
  /**
   * `authV2` is a new store that will replace `auth` store in the future.
   *  it is used to handle the new auth flow with auth0 universal login.
   */
  readonly authV2: UniversalLoginAuthStore
  readonly billing: BillingStore
  readonly blocklist: BlocklistStore
  readonly contact: ContactStore
  readonly conversation: ConversationStore
  readonly integration: IntegrationStore
  readonly member: MemberStore
  readonly organization: OrganizationStore
  readonly participant: ParticipantStore
  readonly phoneNumber: PhoneNumberStore
  readonly search: SearchStore
  readonly snippet: SnippetsStore
  readonly user: UserStore
  readonly voice: VoiceStore
  readonly referral: ReferralStore
  readonly workspace: WorkspaceStore
  readonly emoji: EmojiService
  readonly scheduledMessage: ScheduledMessageStore
  readonly cnam: CnamStore
  readonly flags: IFlagsService
  readonly survey: SurveyService
  readonly capabilities: CapabilitiesService
  readonly portRequest: PortRequestStore
  readonly trustRegistrationV2: TrustRegistrationV2Store
  readonly tollFreeRegistration: TollFreeRegistrationStore
  readonly analytics: AnalyticsStore
  readonly ringOrder: RingOrderStore
  readonly workflow: WorkflowStore
  readonly callFallbackConfig: CallFallbackConfigStore
  readonly contactSuggestion: ContactSuggestionStore
  readonly aiMessageResponse: AiMessageResponseStore
  readonly media: MediaStore
  readonly comment: CommentStore
  readonly tags: TagStore

  constructor(
    protected config: ServiceConfig,
    public desktopVersion: string | undefined,
  ) {
    makeAutoObservable(this, {})
    const worker = this.config.__mock?.worker ?? new MainWorker(this.config.workerName)
    this.storage = this.config.__mock?.storage ?? new StorageService(worker)
    this.flags = this.config.__mock?.flags ?? new FlagsService(this)
    this.presence = new PresenceService(this)
    this.transport = new Transport(this)
    this.activity = new ActivityStore(this)
    this.alert = new AlertStore(this)
    this.auth = new AuthStore(this)
    this.authV2 = new UniversalLoginAuthStore(this)
    this.billing = new BillingStore(this)
    this.blocklist = new BlocklistStore(this)
    this.contact = new ContactStore(this, worker)
    this.conversation = new ConversationStore(this, worker)
    this.integration = new IntegrationStore(this)
    this.member = new MemberStore(this)
    this.organization = new OrganizationStore(this)
    this.participant = new ParticipantStore(this)
    this.phoneNumber = new PhoneNumberStore(this)
    this.search = new SearchStore(this, worker)
    this.snippet = new SnippetsStore(this)
    this.user = new UserStore(this)
    this.referral = new ReferralStore(this)
    this.workspace = new WorkspaceStore(this)
    this.emoji = new EmojiService(this)
    this.scheduledMessage = new ScheduledMessageStore(this)
    this.cnam = new CnamStore(this)
    this.capabilities = new CapabilitiesService(this)
    this.portRequest = new PortRequestStore(this)
    this.trustRegistrationV2 = new TrustRegistrationV2Store(this)
    this.tollFreeRegistration = new TollFreeRegistrationStore(this)
    this.analytics = new AnalyticsStore(this, desktopVersion)
    this.voice = new VoiceStore(this)
    this.ringOrder = new RingOrderStore(this)
    this.workflow = new WorkflowStore(this)
    this.callFallbackConfig = new CallFallbackConfigStore(this)
    this.contactSuggestion = new ContactSuggestionStore(this)
    this.aiMessageResponse = new AiMessageResponseStore(this)
    this.media = new MediaStore(this)
    this.comment = new CommentStore(this)
    this.survey = new SurveyService(this)
    this.tags = new TagStore(this)
    this.ai = new AiStore(this)
  }

  reset = () => {
    return this.storage.reset().then(() => window.location.reload())
  }

  clearAllAndRestart = (evnOverride?: Env) => {
    return this.storage.clearAll().then(() => {
      if (evnOverride) {
        localStorage.setItem(ENV_OVERRIDE_KEY, evnOverride)
      }
      history.replaceState(null, '', '/')
      window.location.reload()
    })
  }
}

export * from './dto'
