import { makeAutoObservable } from 'mobx'

import PersistedCollection from '@src/service/collections/PersistedCollection'
import { TagModel } from '@src/service/model/tags'
import type { CodableTag } from '@src/service/model/tags/TagModel'
import type { TagRepository } from '@src/service/worker/repository/TagRepository'
import { TAG_TABLE_NAME } from '@src/service/worker/repository/TagRepository'

import type Service from '..'

export default class TagStore {
  readonly collection: PersistedCollection<TagModel, TagRepository>

  constructor(private root: Service) {
    this.collection = new PersistedCollection({
      table: root.storage.table(TAG_TABLE_NAME),
      classConstructor: (json: CodableTag) => new TagModel(json),
    })

    makeAutoObservable(
      this,
      {},
      {
        autoBind: true,
      },
    )
  }

  load() {
    return this.collection.performQuery((repo) => repo.all())
  }

  async getByPhoneNumberId(phoneNumberId: string) {
    const tags =
      await this.root.transport.communication.tags.listByPhoneNumberId(phoneNumberId)
    const tagModels = await this.collection.load(tags)
    return tagModels
  }

  async delete({ activityId, tagId }: { activityId: string; tagId: string }) {
    const tag = this.collection.get(tagId)
    const activity = this.root.activity.get(activityId)

    if (!tag || !activity) {
      return
    }

    const liteTagIndexToRemove = activity.tags.findIndex(
      (liteTag) => liteTag.id === tag.id,
    )

    if (liteTagIndexToRemove === -1) {
      return
    }

    const liteTag = activity.tags[liteTagIndexToRemove]
    activity.tags.splice(liteTagIndexToRemove, 1)

    try {
      await this.root.transport.communication.tags.delete({ activityId, tagId })
    } catch (error) {
      activity.tags.splice(liteTagIndexToRemove, 0, liteTag)
      throw error
    }
  }
}
